
import { FormController } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { UserModule } from '../store'

@Component({
  name: 'UserUnsubscribe'
})
export default class extends Vue {
  userModule = getModule(UserModule, this.$store)

  notifications: string[] = []

  formController = new FormController({
    initialModel: {
      authorization: this.$route.query.authorization as string | undefined,
      email: this.$route.query.email as string | undefined,
      update: this.$route.query.update as string | undefined
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      if (!model.authorization) {
        throw new Error('Authorization was not found')
      }
      if (!model.update) {
        throw new Error('Update was not found')
      }

      const update = JSON.parse(atob(model.update))

      await this.userModule.userNotificationsUpdate({
        authorization: atob(model.authorization),
        update
      })

      this.notifications = Object
        .entries(update)
        .filter(([, val]) => !val)
        .map(([key]) => key)

      analytics.userUnsubscribe.track(this)
    }
  }

  mounted () {
    analytics.userUnsubscribeScreen.track(this)

    this.formController.submit()
  }
}
